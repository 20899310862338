<template>
  <div style="padding-left: 10%">
    <div style="font-size: 20px; font-weight: 700; text-align: center">
      编辑计算公式
    </div>
    <div class="blocks2 flex_items flex_start">
      <span class="mr10 liTitle">选择类别</span>
      <el-select
        v-model="box.formulaType"
        placeholder="选择类别"
        :clearable="true"
        style="width: 150px"
        filterable
        @change="typeChange"
        disabled
      >
        <el-option
          v-for="(item, index) in formulaTypes"
          :key="index"
          :label="item.title"
          :value="item.id"
        ></el-option>
      </el-select>
    </div>
    <div class="blocks2 flex_items flex_start">
      <el-input
        v-model="box.resultName"
        placeholder="请输入结果名称"
        style="width: 81%"
      ></el-input>
    </div>
    <div
      v-if="
        box.formulaType == 1 ||
        box.formulaType == 3 ||
        box.formulaType == 104 ||
        box.formulaType == 123
      "
    >
      <div class="blocks2" v-for="(item, index) in lists" :key="index">
        <div v-if="item.moreChoice == 1">
          <div class="mr10 liTitle mb10">{{ item.title }}(单选)</div>
          <el-radio-group
            v-model="item.value"
            style="width: 81%"
            @change="valueChange(item)"
          >
            <el-radio-button
              :label="item1.id"
              v-for="(item1, index1) in item.data"
              :key="index1"
              >{{ item1.title }}</el-radio-button
            >
          </el-radio-group>
        </div>
        <div v-else>
          <div class="mr10 liTitle mb10">{{ item.title }}(多选)</div>
          <el-checkbox-group v-model="item.value">
            <el-checkbox-button
              v-for="(item1, index1) in item.data"
              :label="item1.id"
              :key="index1"
              >{{ item1.title }}</el-checkbox-button
            >
          </el-checkbox-group>
        </div>
      </div>
      <div class="flex_warp" style="margin: 10px 0">
        <el-input
          type="textarea"
          placeholder="请输入提醒事项，回车换行"
          v-model="box.reminder"
          style="width: 81%"
        >
        </el-input>
      </div>
      <div>
        <el-button
          type="primary"
          plain
          size="medium"
          style="width: 81%"
          @click="submitAdd"
          >确认编辑</el-button
        >
      </div>
    </div>
    <div v-if="box.formulaType == 42">
      <div class="blocks2" v-for="item in lists1" :key="item.index">
        <div v-if="item.moreChoice == 1">
          <div class="mr10 liTitle mb10">{{ item.title }}(单选)</div>
          <el-radio-group v-model="item.value" style="width: 81%">
            <el-radio-button
              :label="item1.id"
              v-for="(item1, index1) in item.data"
              :key="index1"
              >{{ item1.title }}</el-radio-button
            >
          </el-radio-group>
        </div>
        <div v-else>
          <div class="mr10 liTitle mb10">{{ item.title }}(多选)</div>
          <el-checkbox-group v-model="item.value">
            <el-checkbox-button
              v-for="(item1, index1) in item.data"
              :label="item1.id"
              :key="index1"
              >{{ item1.title }}</el-checkbox-button
            >
          </el-checkbox-group>
        </div>
      </div>
      <div class="blocks2">
        <el-radio-group
          v-model="radio"
          @change="chooseRadio"
          v-if="lists1.length == 4"
        >
          <el-radio :label="0" style="display: block; margin: 10px 0"
            >直接出结果</el-radio
          >
          <el-radio :label="1" style="display: block; margin: 10px 0"
            >跳转其他流程</el-radio
          >
          <el-radio :label="2" style="display: block; margin: 10px 0"
            >跳转到下一级(NQR)</el-radio
          >
        </el-radio-group>
        <el-radio-group
          v-model="radio1"
          @change="chooseRadio1"
          v-if="lists1.length == 6"
        >
          <el-radio :label="0" style="display: block; margin: 10px 0"
            >直接出结果</el-radio
          >
          <el-radio :label="1" style="display: block; margin: 10px 0"
            >跳转其他流程</el-radio
          >
          <el-radio :label="2" style="display: block; margin: 10px 0"
            >跳转到下一级(NQR)</el-radio
          >
        </el-radio-group>
        <el-radio-group
          v-model="radio2"
          @change="chooseRadio2"
          v-if="lists1.length >= 7"
        >
          <el-radio :label="0" style="display: block; margin: 10px 0"
            >直接出结果</el-radio
          >
          <!-- <el-radio :label="1" style="display:block;margin:10px 0;" disabled
            >跳转其他流程</el-radio
          >
          <el-radio :label="2" style="display:block;margin:10px 0;" disabled
            >跳转到下一级(NQR)</el-radio
          > -->
        </el-radio-group>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["currentPage"],
  data() {
    return {
      box: {
        formulaType: "",
        resultName: "",
        reminder: "",
      },
      locations: [],
      startTimes: [],
      fixRecords: [],
      conditions: [],
      checkRecords: [],
      wallWaters: [],
      formulaTypes: [],
      data: [],
      num1: "",
      num2: "",
      num3: "",
      num4: "",
      num5: "",
      num6: "",
      mark: "",
      lists: [],
      lists1: [],
      lists2: [],
      radio: "",
      radio1: "",
      radio2: "",
      data2: [],
      data3: [],
      data4: [],
      array: [],
      arr1: [],
      arr2: [],
      arr3: [],
      radioChild: "",
      tempList: [],
    };
  },
  mounted() {
    // this.getChoice();
    this.getproblems(241);
    this.getData();
  },
  methods: {
    getData() {
      this.$loading(this.util.opload);
      this.util
        .get(this.HOST + "/Diagnostic/info", {
          id: this.$store.state.formula_id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.box.resultName = res.data.title;
            this.box.formulaType = res.data.goods_category_id;
            this.array = res.data.data;
            if (this.box.formulaType == 1) {
              this.getDiagnostic();
            }
            if (this.box.formulaType == 3 || this.box.formulaType == 104) {
              this.valueChange2(res.data.data[0].data[0].id);
            }
            if (this.box.formulaType == 42) {
              this.getDiagnostic2();
            }
            if (res.data.result.length == 1) {
              this.radio = res.data.result[0];
            } else if (res.data.result.length == 2) {
              this.radio = res.data.result[0];
              this.radio1 = res.data.result[1];
            } else {
              this.radio = "";
              this.radio1 = "";
            }
            if (this.radio == 2) {
              this.chooseRadio();
            }
            if (this.radio1 == 2) {
              this.chooseRadio1();
            }

            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    getproblems(shengid) {
      if (shengid) {
        this.util
          .get(this.HOST + "/Common/programme", {
            city_id: shengid,
          })
          .then((res) => {
            if (res.code == 200) {
              this.formulaTypes = res.data;
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
                duration: 1200,
                offset: 90,
              });
            }
          });
      }
    },
    // 获取方案类别
    getChoice() {
      this.util.get(this.HOST + "/Diagnostic/all", {}).then((res) => {
        if (res.code == 200) {
          this.formulaTypes = res.data.label;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    getDiagnostic() {
      //获取基检列表
      this.util
        .get(this.HOST + "/Diagnostic/diagnostic", {
          problemId: this.box.formulaType,
        })
        .then((res) => {
          if (res.code == 200) {
            this.lists = res.data;
            this.tempList = res.data;
            if (this.array.length != 0) {
              for (let i = 0; i < this.array.length; i++) {
                for (let j = 0; j < this.lists.length; j++) {
                  if (this.array[i].type == this.lists[j].type) {
                    this.lists[j].value = this.array[i].data[0].id;
                  }
                }
              }
            }
            this.lists = JSON.parse(JSON.stringify(this.lists));
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    getDiagnostic2() {
      //获取基检列表
      this.util
        .get(this.HOST + "/Diagnostic/diagnostic", {
          problemId: this.box.formulaType,
        })
        .then((res) => {
          if (res.code == 200) {
            this.lists2 = res.data;
            // for (let i = 0; i < this.lists.length; i++) {
            //   this.lists2[i].index = i;
            // }
            if (this.array.length != 0) {
              if (this.array.length == 4) {
                this.lists1 = this.lists2.slice(0, 4);
              } else if (this.array.length == 6) {
                this.lists1 = this.lists2.slice(0, 6);
              } else if (this.array.length == 11) {
                this.lists1 = this.lists2;
              }
              for (let i = 0; i < this.array.length; i++) {
                for (let j = 0; j < this.lists1.length; j++) {
                  if (this.array[i].type == this.lists1[j].type) {
                    this.lists1[j].value = this.array[i].data[0].id;
                  }
                }
              }
              //console.log(this.lists1, "data++++");
              this.lists1 = JSON.parse(JSON.stringify(this.lists1));
            } else {
              this.lists1 = this.lists2.slice(0, 4);
            }
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    typeChange() {
      this.array = [];
      if (
        this.box.formulaType == 1 ||
        this.box.formulaType == 3 ||
        this.box.formulaType == 104 ||
        this.box.formulaType == 123
      ) {
        this.getDiagnostic();
      } else if (this.box.formulaType == 42) {
        this.getDiagnostic2();
      }
      this.box.resultName = "";
      this.box.reminder = "";
      this.radio = "";
      this.radio1 = "";
      this.radio2 = "";
    },
    chooseRadio() {
      this.data = [];
      if (this.radio == 2) {
        this.lists1 = this.lists2.slice(0, 6);
      }

      if (this.radio == 0 || this.radio == 1) {
        for (let i = 0; i < this.lists1.length; i++) {
          this.data.push({
            result: this.radio,
            type: this.lists1[i].type,
            id: this.lists1[i].value,
          });
        }
        if (this.box.resultName == "") {
          this.$message({
            showClose: true,
            message: "请填写结果名称！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          this.radio = "";
          return false;
        }
        if (this.lists1[0].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择渗漏状况！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          this.radio = "";
          return false;
        }
        if (this.lists1[1].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择渗漏位置！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          this.radio = "";
          return false;
        }
        if (this.lists1[2].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择渗漏开始时间！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          this.radio = "";
          return false;
        }
        if (this.lists1[3].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择之前维修记录！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          this.radio = "";
          return false;
        }
        this.util
          .post(this.HOST + "/Diagnostic/add", {
            title: this.box.resultName,
            reminders: this.box.reminder,
            goods_category_id: this.box.formulaType,
            data: JSON.stringify(this.data),
          })
          .then((res) => {
            if (res.code == 200) {
              this.$message({
                showClose: true,
                message: "添加公式成功！",
                type: "success",
                duration: 1200,
                offset: 90,
              });
              setTimeout(() => {
                this.$store.commit("setFormulaDialog", false);
                this.$store.commit("settask", 1);
                this.$emit("getDataAgain", this.currentPage);
                this.$emit("update:currentPage", this.currentPage);
              }, 1200);
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
                duration: 1200,
                offset: 90,
              });
            }
          });
      }
    },
    chooseRadio1() {
      this.data = [];
      this.arr1 = [];
      this.arr2 = [];
      if (this.radio1 == 2) {
        this.lists1 = this.lists2;
      }
      if (this.radio1 == 0 || this.radio1 == 1) {
        if (this.box.resultName == "") {
          this.$message({
            showClose: true,
            message: "请填写结果名称！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          this.radio1 = "";
          return false;
        }
        if (this.lists1[0].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择渗漏状况！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
        if (this.lists1[1].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择渗漏位置！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
        if (this.lists1[2].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择渗漏开始时间！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
        if (this.lists1[3].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择之前维修记录！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
        if (this.lists1[4].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择五金外观检测！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
        if (this.lists1[5].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择水管压力测试！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
        this.data2 = this.lists1.slice(0, 4);
        this.data3 = this.lists1.slice(4, 6);
        for (let i = 0; i < this.data2.length; i++) {
          this.arr1.push({
            result: this.radio,
            type: this.lists1[i].type,
            id: this.lists1[i].value,
          });
        }
        for (let i = 4; i < 6; i++) {
          this.arr2.push({
            result: this.radio1,
            type: this.lists1[i].type,
            id: this.lists1[i].value,
          });
        }
        let a = this.arr1.concat(this.arr2);
        // return;
        this.util
          .post(this.HOST + "/Diagnostic/add", {
            title: this.box.resultName,
            reminders: this.box.reminder,
            goods_category_id: this.box.formulaType,
            data: JSON.stringify(a),
          })
          .then((res) => {
            if (res.code == 200) {
              this.$message({
                showClose: true,
                message: "添加公式成功！",
                type: "success",
                duration: 1200,
                offset: 90,
              });
              setTimeout(() => {
                this.$store.commit("setFormulaDialog", false);
                this.$store.commit("settask", 1);
                this.$emit("getDataAgain", this.currentPage);
                this.$emit("update:currentPage", this.currentPage);
              }, 1200);
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
                duration: 1200,
                offset: 90,
              });
            }
          });
      }
    },
    chooseRadio2() {
      this.data = [];
      this.arr1 = [];
      this.arr2 = [];
      this.arr3 = [];
      if (this.radio2 == 0) {
        this.lists1 = this.lists2;
        if (this.box.resultName == "") {
          this.$message({
            showClose: true,
            message: "请填写结果名称！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          this.radio2 = "";
          return false;
        }
        if (this.lists1[0].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择渗漏状况！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          this.radio2 = "";
          return false;
        }
        if (this.lists1[1].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择渗漏位置！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          this.radio2 = "";
          return false;
        }
        if (this.lists1[2].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择渗漏开始时间！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          this.radio2 = "";
          return false;
        }
        if (this.lists1[3].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择之前维修记录！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          this.radio2 = "";
          return false;
        }
        if (this.lists1[4].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择五金外观检测！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          this.radio2 = "";
          return false;
        }
        if (this.lists1[5].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择水管压力测试！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          this.radio2 = "";
          return false;
        }
        if (this.lists1[6].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择卫生间布局类型！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          this.radio2 = "";
          return false;
        }
        if (this.lists1[7].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择洁具类型！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          this.radio2 = "";
          return false;
        }
        if (this.lists1[8].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择地砖检测！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          this.radio2 = "";
          return false;
        }
        if (this.lists1[9].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择地漏检测！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          this.radio2 = "";
          return false;
        }
        if (this.lists1[10].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择排水情况！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          this.radio2 = "";
          return false;
        }
        this.data2 = this.lists1.slice(0, 4);
        this.data3 = this.lists1.slice(4, 6);
        this.data4 = this.lists1.slice(6, 10);
        for (let i = 0; i < this.data2.length; i++) {
          this.arr1.push({
            result: this.radio,
            type: this.lists1[i].type,
            id: this.lists1[i].value,
          });
        }
        for (let i = 4; i < 6; i++) {
          this.arr2.push({
            result: this.radio1,
            type: this.lists1[i].type,
            id: this.lists1[i].value,
          });
        }
        for (let i = 6; i < this.lists1.length; i++) {
          this.arr3.push({
            result: this.radio2,
            type: this.lists1[i].type,
            id: this.lists1[i].value,
          });
        }
        let a = this.arr1.concat(this.arr2);
        let b = a.concat(this.arr3);
        // return;
        this.util
          .post(this.HOST + "/Diagnostic/add", {
            title: this.box.resultName,
            reminders: this.box.reminder,
            goods_category_id: this.box.formulaType,
            data: JSON.stringify(b),
          })
          .then((res) => {
            if (res.code == 200) {
              this.$message({
                showClose: true,
                message: "添加公式成功！",
                type: "success",
                duration: 1200,
                offset: 90,
              });
              setTimeout(() => {
                this.$store.commit("setFormulaDialog", false);
                this.$store.commit("settask", 1);
                this.$emit("getDataAgain", this.currentPage);
                this.$emit("update:currentPage", this.currentPage);
              }, 1200);
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
                duration: 1200,
                offset: 90,
              });
            }
          });
      }
    },
    valueChange(item) {
      //console.log(item.value, "p");
      if (item.data[0].click == 1) {
        this.radioChild = item.value;
        this.util
          .get(this.HOST + "/Diagnostic/subordinate", {
            problemId: this.box.formulaType,
            id: item.value,
          })
          .then((res) => {
            if (res.code == 200) {
              this.lists = this.tempList.concat(res.data);
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
                duration: 1200,
                offset: 90,
              });
            }
          });
      }
    },
    valueChange2(value) {
      this.radioChild = value;
      this.util
        .get(this.HOST + "/Diagnostic/subordinate", {
          problemId: this.box.formulaType,
          id: value,
        })
        .then((res) => {
          if (res.code == 200) {
            this.lists = this.tempList.concat(res.data);
            if (this.array.length != 0) {
              for (let i = 0; i < this.array.length; i++) {
                for (let j = 0; j < this.lists.length; j++) {
                  if (this.array[i].type == this.lists[j].type) {
                    this.lists[j].value = this.array[i].data[0].id;
                  }
                }
              }
            }
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    submitAdd() {
      //console.log(this.radioChild == "");
      if (this.box.formulaType == "") {
        this.$message({
          showClose: true,
          message: "请选择类别！",
          type: "error",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.box.resultName == "") {
        this.$message({
          showClose: true,
          message: "请输入结果名称！",
          type: "error",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.box.formulaType == 1) {
        if (this.lists[0].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择墙病呈现状况！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
        if (this.lists[1].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择发生时间！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
        if (this.lists[2].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择维修记录！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
        if (this.lists[3].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择损坏位置！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
        if (this.lists[4].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择墙体基础空鼓检测记录！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
        if (this.lists[5].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择墙体含水率检测！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
      }
      if (this.box.formulaType == 3) {
        if (this.lists[0].value == undefined) {
          //console.log("类型题提示");
          this.$message({
            showClose: true,
            message: "请选择改造类型！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
        if (this.radioChild == 57) {
          if (this.lists[1].value == undefined) {
            this.$message({
              showClose: true,
              message: "请选择改造区域！",
              type: "error",
              duration: 1200,
              offset: 90,
            });
            return false;
          }
          if (this.lists[2].value == undefined) {
            this.$message({
              showClose: true,
              message: "请选择改造目的！",
              type: "error",
              duration: 1200,
              offset: 90,
            });
            return false;
          }
          if (this.lists[3].value == undefined) {
            this.$message({
              showClose: true,
              message: "请选择家庭结构！",
              type: "error",
              duration: 1200,
              offset: 90,
            });
            return false;
          }
          if (this.lists[4].value == undefined) {
            this.$message({
              showClose: true,
              message: "请选择孩子数量！",
              type: "error",
              duration: 1200,
              offset: 90,
            });
            return false;
          }
          if (this.lists[5].value == undefined) {
            this.$message({
              showClose: true,
              message: "请选择改造首要关心点！",
              type: "error",
              duration: 1200,
              offset: 90,
            });
            return false;
          }
          if (this.lists[6].value == undefined) {
            this.$message({
              showClose: true,
              message: "请选择房屋的使用方式！",
              type: "error",
              duration: 1200,
              offset: 90,
            });
            return false;
          }
          if (this.lists[7].value == undefined) {
            this.$message({
              showClose: true,
              message: "请选择是否需要拆旧！",
              type: "error",
              duration: 1200,
              offset: 90,
            });
            return false;
          }
          if (this.lists[8].value == undefined) {
            this.$message({
              showClose: true,
              message: "请选择接受工期！",
              type: "error",
              duration: 1200,
              offset: 90,
            });
            return false;
          }
          if (this.lists[9].value == undefined) {
            this.$message({
              showClose: true,
              message: "请选择服务要求！",
              type: "error",
              duration: 1200,
              offset: 90,
            });
            return false;
          }
        }
        if (this.radioChild == 58) {
          if (this.lists[1].value == undefined) {
            this.$message({
              showClose: true,
              message: "请选择工装类型！",
              type: "error",
              duration: 1200,
              offset: 90,
            });
            return false;
          }
          if (this.lists[2].value == undefined) {
            this.$message({
              showClose: true,
              message: "请选择管理要求！",
              type: "error",
              duration: 1200,
              offset: 90,
            });
            return false;
          }
        }
      }
      if (this.box.formulaType == 104) {
        if (this.lists[0].value == undefined) {
          this.$message({
            showClose: true,
            message: "请选择维修类型！",
            type: "error",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
        if (this.radioChild == 127) {
          if (this.lists[1].value == undefined) {
            this.$message({
              showClose: true,
              message: "请选择维修类型！",
              type: "error",
              duration: 1200,
              offset: 90,
            });
            return false;
          }
          if (this.radioChild == 128) {
            if (this.lists[1].value == undefined) {
              this.$message({
                showClose: true,
                message: "请选择安装类型！",
                type: "error",
                duration: 1200,
                offset: 90,
              });
              return false;
            }
          }
        }
      }
      // }
      for (let i = 0; i < this.lists.length; i++) {
        this.data.push({
          result: "",
          type: this.lists[i].type,
          id: this.lists[i].value,
        });
      }
      this.util
        .post(this.HOST + "/Diagnostic/add", {
          id: this.$store.state.formula_id,
          title: this.box.resultName,
          reminders: this.box.reminder,
          goods_category_id: this.box.formulaType,
          data: JSON.stringify(this.data),
          // data:this.data
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "添加公式成功！",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            setTimeout(() => {
              this.$store.commit("setFormulaDialog", false);
              // this.$store.commit("settask", 1);
              this.$emit("getDataAgain", this.currentPage);
              this.$emit("update:currentPage", this.currentPage);
            }, 1200);
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
  },
};
</script>
<style scoped>
.blocks2 {
  margin-right: 20px;
  margin-top: 10px;
}
</style>
